import { useMemo } from 'react'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import dayjs, { Dayjs } from 'dayjs'
import { Form, FormInstance } from 'antd'

/**
 * 响应式的 form.getFieldsValue(true)
 */
export const useFormAllValues = <T extends Record<string, any> | any[]>(
  form: FormInstance<T>,
  initialFormValues: T,
): T => {
  return (
    Form.useWatch(() => {
      const values = form.getFieldsValue(true)
      return _.isEmpty(values) ? initialFormValues : values
    }, form) || initialFormValues
  )
}

/**
 * 获取日期范围预设
 */
export const useDateRangePresets = Object.assign(
  () => {
    const { t } = useTranslation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => useDateRangePresets.getDateRangePresets(), [t])
  },
  {
    /** 获取日期范围预设    */
    getDateRangePresets: () => ({
      /** 今天 */
      today: {
        label: t('5-util.jin-tian'),
        value: [dayjs(), dayjs()] as [Dayjs, Dayjs],
      },
      /** 昨天 */
      yesterday: {
        label: t('5-util.zuo-tian'),
        value: [dayjs().add(-1, 'day'), dayjs().add(-1, 'day')] as [Dayjs, Dayjs],
      },
      /** 本周 */
      thisWeek: {
        label: t('5-util.ben-zhou'),
        value: [dayjs().startOf('week'), dayjs()] as [Dayjs, Dayjs],
      },
      /** 上周 */
      lastWeek: {
        label: t('5-util.shang-zhou'),
        value: [dayjs().add(-1, 'week').startOf('week'), dayjs().add(-1, 'week').endOf('week')] as [Dayjs, Dayjs],
      },
      /** 本月 */
      thisMonth: {
        label: t('5-util.ben-yue'),
        value: [dayjs().startOf('month'), dayjs()] as [Dayjs, Dayjs],
      },
      /** 上月 */
      lastMonth: {
        label: t('5-util.shang-yue'),
        value: [dayjs().add(-1, 'month').startOf('month'), dayjs().add(-1, 'month').endOf('month')] as [Dayjs, Dayjs],
      },
      /** 最近7天 */
      last7days: {
        label: t('5-util.zui-jin-7-tian'),
        value: [dayjs().add(-6, 'day'), dayjs()] as [Dayjs, Dayjs],
      },
      /** 最近30天 */
      last30days: {
        label: t('5-util.zui-jin-30-tian'),
        value: [dayjs().add(-29, 'day'), dayjs()] as [Dayjs, Dayjs],
      },
      /** 最近60天 */
      last60days: {
        label: t('5-util.zui-jin-60-tian'),
        value: [dayjs().add(-59, 'day'), dayjs()] as [Dayjs, Dayjs],
      },
      /** 最近6个月 */
      last6months: {
        label: t('5-util.zui-jin-6-ge-yue'),
        value: [dayjs().add(-5, 'month').startOf('month'), dayjs()] as [Dayjs, Dayjs],
      },
    }),
  },
)
